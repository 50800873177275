<template>
  <div class="copyright">
      <h1>版权声明<i></i></h1>

      <h4>CC共享版权授权协议</h4>

      <p>“知识共享”是 Creative Commons 在中国大陆地区的通用译名，一般简称为 CC 。 CC 既是该国际组织的名称缩写，也是一种版权授权协议的统称。</p>

      <h4>共享： CC-BY-NC 授权协议</h4>

      <p>署名-非商业性使用（BY-NC）：只要在使用、公开时进行署名，那么被授权人可以对本创作进行转载、节选、混编、二次创作，但不得将本创作或由本创作衍生的创作运用于商业目的。</p>

      <div>
        <!-- <p style="margin-bottom:0">申请《CC共享版权授权协议》官网：</p>
        <a href="https://creativecommons.org/choose/?lang=zh">https://creativecommons.org/choose/?lang=zh</a> -->
      </div>

        <div>

      <p>享设计是一家共享网络服务平台方，由于平台上的作品均由设计师自行上传后供用户学习和交流使用，所以上传者拥有该作品的全部版权。</p>

      <p>享设计根据用户指令提供作品上传以及下载等，向来十分重视网络版权及其他知识产权以及用户权益的保护。但为切实维护权利人的合法权益，享设计向用户传达版权保护理念：</p>

      <p>1. 用户上传作品须在享设计平台进行实名登记，并按要求上传身份证和手机号码进行身份验证，并对身份真实性负责。</p>
      
      <p>2. 享设计对上传作品进行严格审查，向网络用户公示基本审查标准，提示用户严格遵守法律法规及尊重他人合法权益，不得上传和传播任何非法或侵权的作品。</p>

      <p>3. 对于违反规定，上传侵权作品的用户，享设计有权根据网站相关规定对其进行处罚。若因侵权行为给第三人造成损失，上传用户将承担由此造成的损害赔偿责任。</p>

      <p>4. 对于有理由确信存在明显侵权、违法、违反公共利益等情形的作品时，享设计有权不事先通知并有权删除该等侵权作品。</p>
      
      <p>5. 用户下载享设计上的共享作品，只应在学习、交流、分享的范围内使用，不得用于任何商业用途的范围。</p>

      <p>6. 享设计严格保护上传平台的设计师作品，未经作者和享设计的授权许可，禁止用户转载享设计的作品到第三方网站上进行二次售卖谋取利益，一经发现，立即封号，享设计将依法追究其相关法律责任。 </p>
    </div>
      
      <p>用户使用享设计服务即视为同意享设计就上述情况所采用的相应措施，享设计不因此承担任何违约责任或其他任何法律责任。</p>

      <p>若您的权益被侵害，请及时发邮件联系我们<em>（kefu@design006.com）</em>。在您发送邮件后，工作人员会尽快处理，由于涉及内容不同，所需周期也不同，请您耐心等待。 </p>
      
  </div>
</template>

<script>
export default {

}
</script>

<style>
.copyright{padding:47px 20px 20px; background: #fff;}
.copyright h1{margin: 24px 0 32px; text-align: center; font-size:20px ;color: #2E2E2E;  position: relative; font-weight: normal;}
.copyright h1 i{display: block; width: 30px; height: 2px; background:#F85659 ; position: absolute; left: 0; right: 0; bottom: -10px; margin: auto; z-index: 1;}
.copyright p{font-size: 14px; color: #B3B3B3; line-height: 24px; margin-bottom: 32px;}
.copyright h4{font-size: 14px; color: #2E2E30; line-height: 24px;font-weight: normal;}
.copyright div{border-bottom: 1px solid #EDEDED; margin-bottom: 32px;}
.copyright a{font-size: 14px; color: #2E2E30;  line-height: 24px; margin-bottom: 32px; text-decoration: underline;}
.copyright em{font-style: normal; color: #F85659;}
</style>